#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  font-family: Inter, Gotham Pro, Arial, sans-serif;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BoldItalic.eot);
  src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BoldItalic.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BoldItalic.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Italic.eot);
  src: local('Gotham Pro Italic'), local('GothamPro-Italic'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Italic.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Italic.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Medium.eot);
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Medium.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Medium.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BlackItalic.eot);
  src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BlackItalic.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BlackItalic.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Bold.eot);
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Bold.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Bold.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-LightItalic.eot);
  src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-LightItalic.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-LightItalic.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Light.eot);
  src: local('Gotham Pro Light'), local('GothamPro-Light'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Light.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Light.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Black.eot);
  src: local('Gotham Pro Black'), local('GothamPro-Black'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Black.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Black.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro.eot);
  src: local('Gotham Pro'), local('GothamPro'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro.ttf) format('truetype');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Gotham Pro';
  src: url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-MediumItalic.eot);
  src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-MediumItalic.eot?#iefix) format('embedded-opentype'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-MediumItalic.woff) format('woff'), url(https://scaleflex.ultrafast.io/https://jolipage.airstore.io/v3/themes/space/css/fonts/gotham_pro/GothamPro-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic
}

/* width */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #F4F4FC;
  box-shadow: inset 0 0 2px #DCDEF5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #DCDEF5;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b0b4e7; /* #5164CD; */
}

/* LINK styles. We need it mostly for translations with html inside which include links. */
a {
  text-decoration: none !important;
  color: #072dc5; /* #5164CD; */
  margin: 0;
}
a:hover:not(.no-text-decoration,.MuiButtonBase-root) {
  text-decoration: underline;
}
